import React from "react";
import classes from "./ContestLayout.module.css";
import date from "../helpers/date";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PredictContestLayout = ({ match, showPoints, hideButton }) => {
  const navigate = useNavigate();
  const { lang } = useSelector((state) => state.langSlice);
  const handleNavigate = (path) => {
    navigate(
      `/user/match/prediction/${path.matchId}/${path.homeTeamId}/${path.awayTeamId}`
    );
  };

  return (
    <div className={`${classes.match_flex_col}`}>
      {match?.matchStatus == "Match Finished" && (
        <div className={classes.match_status}>
          <p className={classes.status_text}>FT</p>
        </div>
      )}
      {match?.matchTime != null && match?.matchStatus != "Match Finished" && (
        <>
          <div className={classes.match_status}>
            <p className={classes.status_text}>
              <i
                className={`fa fa-circle ${classes.text_danger_glow} ${classes.blink}`}
                style={{ fontSize: "10px" }}
              ></i>
              <span style={{ marginLeft: "10px" }}>
                {match?.matchStatus === "Halftime" ? (
                  lang == "english" ? (
                    "Half-Time"
                  ) : lang == "spanish" ? (
                    "Medio tiempo"
                  ) : lang == "french" ? (
                    "Mi-temps"
                  ) : lang == "arabic" ? (
                    "نصف الوقت"
                  ) : (
                    "Half-Time"
                  )
                ) : (
                  <>{match?.matchTime}`</>
                )}
              </span>
            </p>
          </div>
        </>
      )}
      <div className={classes.match_container}>
        <div className={classes.match_sub_container}>
          <div className={classes.home_team_section}>
            <div className={classes.home_team_logo_name}>
              <div className={classes.home_logo_container}>
                <img
                  src={match?.homeLogo}
                  alt={match?.homeTeam}
                  className={classes.home_logo}
                />
              </div>
              <div className={classes.home_team_name}>
                <p className={classes.name}>{match?.homeTeam}</p>
              </div>
            </div>
            <div className={classes.home_team_goals}>
              <p className={classes.name}>{match?.homeGoals}</p>
            </div>
          </div>

          <div className={classes.away_team_section}>
            <div className={classes.away_team_logo_name}>
              <div className={classes.away_logo_container}>
                <img
                  src={match?.awayLogo}
                  alt={match?.awayTeam}
                  className={classes.away_logo}
                />
              </div>
              <div className={classes.away_team_name}>
                <p className={classes.name}>{match?.awayTeam}</p>
              </div>
            </div>
            <div className={classes.away_team_goals}>
              <p className={classes.name}>{match?.awayGoals}</p>
            </div>
          </div>
        </div>

        <div className={classes.match_date_container}>
          <div className={classes.line}></div>
          <div className={classes.match_date_time}>
            <p className={classes.date}>{date(match?.time)?.date}</p>
            <p className={classes.time}>{date(match?.time)?.time}</p>
          </div>
        </div>
      </div>

      <div className={classes.divider}></div>

      <div className={classes.contest_details}>
        <p className={classes.contest_heading}>
          {lang == "english"
            ? "Contest Details"
            : lang == "spanish"
            ? "Detalles del concurso"
            : lang == "french"
            ? "Détails du concours"
            : lang == "arabic"
            ? "تفاصيل المسابقة"
            : "Contest Details"}
        </p>
        <div className={classes.details}>
          <p className={classes.contest}>
            {lang == "english"
              ? "Entry Fees:"
              : lang == "spanish"
              ? "Derechos de inscripción:"
              : lang == "french"
              ? "Frais d'entrée:"
              : lang == "arabic"
              ? "رسوم الدخول:"
              : "Entry Fees:"}{" "}
            {match?.contestDetail?.entry_fee
              ? match?.contestDetail?.entry_fee
              : lang == "english"
              ? "Soon to be declared!"
              : lang == "spanish"
              ? "¡Pronto será declarado!"
              : lang == "french"
              ? "Bientôt déclaré !"
              : lang == "arabic"
              ? "قريبا سيتم الإعلان عنها!"
              : "Soon to be declared!"}
          </p>
          <p className={classes.contest}>
            {lang == "english"
              ? "Prize 1:"
              : lang == "spanish"
              ? "Premio 1:"
              : lang == "french"
              ? "Prix ​​1 :"
              : lang == "arabic"
              ? "الجائزة 1:"
              : "Prize 1:"}{" "}
            {match?.contestDetail?.prize1
              ? match?.contestDetail?.prize1
              : lang == "english"
              ? "Soon to be declared!"
              : lang == "spanish"
              ? "¡Pronto será declarado!"
              : lang == "french"
              ? "Bientôt déclaré !"
              : lang == "arabic"
              ? "قريبا سيتم الإعلان عنها!"
              : "Soon to be declared!"}
          </p>
          <p className={classes.contest}>
            {lang == "english"
              ? "Prize 2:"
              : lang == "spanish"
              ? "Premio 2:"
              : lang == "french"
              ? "Prix ​​2 :"
              : lang == "arabic"
              ? "الجائزة 2:"
              : "Prize 2:"}{" "}
            {match?.contestDetail?.prize2
              ? match?.contestDetail?.prize2
              : lang == "english"
              ? "Soon to be declared!"
              : lang == "spanish"
              ? "¡Pronto será declarado!"
              : lang == "french"
              ? "Bientôt déclaré !"
              : lang == "arabic"
              ? "قريبا سيتم الإعلان عنها!"
              : "Soon to be declared!"}
          </p>
          <p className={classes.contest}>
            {lang == "english"
              ? "Prize 3:"
              : lang == "spanish"
              ? "Premio 3:"
              : lang == "french"
              ? "Prix ​​3 :"
              : lang == "arabic"
              ? "الجائزة 3:"
              : "Prize 3:"}{" "}
            {match?.contestDetail?.prize3
              ? match?.contestDetail?.prize3
              : lang == "english"
              ? "Soon to be declared!"
              : lang == "spanish"
              ? "¡Pronto será declarado!"
              : lang == "french"
              ? "Bientôt déclaré !"
              : lang == "arabic"
              ? "قريبا سيتم الإعلان عنها!"
              : "Soon to be declared!"}
          </p>
          <p className={classes.contest}>
            {lang == "english"
              ? "Users Joined"
              : lang == "spanish"
              ? "Usuarios unidos"
              : lang == "french"
              ? "Utilisateurs rejoints"
              : lang == "arabic"
              ? "انضم المستخدمون"
              : "Users Joined"}
            :
            {match?.contestDetail?.users_joined
              ? match?.contestDetail?.users_joined
              : lang == "english"
              ? "0"
              : lang == "spanish"
              ? "0"
              : lang == "french"
              ? "0"
              : lang == "arabic"
              ? "0"
              : "0"}
            {}
          </p>
          {showPoints && (
            <p className={classes.contest}>
              {lang == "english"
                ? "Your Points:"
                : lang == "spanish"
                ? "Tus puntos:"
                : lang == "french"
                ? "Vos points :"
                : lang == "arabic"
                ? "نقاطك:"
                : "Your Points:"}{" "}
              {match?.total_points
                ? match?.total_points
                : lang == "english"
                ? "Soon to be declared!"
                : lang == "spanish"
                ? "¡Pronto será declarado!"
                : lang == "french"
                ? "Bientôt déclaré !"
                : lang == "arabic"
                ? "قريبا سيتم الإعلان عنها!"
                : "Soon to be declared!"}
            </p>
          )}

          {!hideButton && (
            <div className={classes.match_btns}>
              <button
                className={classes.monitor_btn}
                type="button"
                onClick={() =>
                  handleNavigate({
                    matchId: match?.fixture_id,
                    homeTeamId: match?.homeTeamId,
                    awayTeamId: match?.awayTeamId,
                  })
                }
              >
                {lang == "english"
                  ? "View Prediction"
                  : lang == "spanish"
                  ? "Ver predicción"
                  : lang == "french"
                  ? "Afficher la prévision"
                  : lang == "arabic"
                  ? "عرض التنبؤ"
                  : "View Prediction"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PredictContestLayout;
