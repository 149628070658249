import React from "react";
import Routing from "./routing/Routing";
import Language from "./components/Language";

const App = () => {
  return (
    <>
      <Routing />
      <Language />
    </>
  );
};

export default App;
