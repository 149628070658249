import React from "react";
import classes from "./MessageBoxSimple.module.css";

const MessageBoxSimple = ({ title }) => {
  return (
    <div className={classes.message_box}>
      <p className={classes.message}>{title}</p>
    </div>
  );
};

export default MessageBoxSimple;
