import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  editModal: false,
  data:{},
};

const editModalSlice = createSlice({
  name: "editModalSlice",
  initialState,
  reducers: {
    setEditModal: (state, action) => {
      state.editModal = true;
      state.data=action.payload;
      return state;
    },
    resetEditModal: (state,action) => {
      state.editModal = false;
      state.data={};
      return state;
    },
  },
});

export const { setEditModal,resetEditModal } = editModalSlice.actions;
export default editModalSlice;
