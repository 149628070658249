import React, { useEffect, useState } from "react";
import Loader from "../components/Loader";
import Drawer from "../components/Drawer";
import Layout from "../components/Layout";
import classes from "../css/SearchPage.module.css";
import moment from "moment-timezone";
import { useNavigate } from "react-router-dom";
import MatchLayout from "../components/MatchLayout";
import { allMatchesApi, baseUrl } from "../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import date from "../helpers/date";
import { useSelector } from "react-redux";
import MatchGridLayout from "../components/Match-Container/MatchGridLayout";
import HeadingWithGrid from "../components/Headings/HeadingWithGrid";
import MessageBoxSimple from "../components/Message-Box/MessageBoxSimple";

const SearchPage = () => {
  const [loading, setLoading] = useState(true);
  const [matches, setMatches] = useState([]);

  const [startDateForCalendar, setStartDateForCalendar] = useState(() =>
    moment().subtract(14, "days").toDate()
  );

  const [endDateForCalendar, setEndDateForCalendar] = useState(
    () => new Date()
  );

  const [startDate, setStartDate] = useState(() =>
    moment().subtract(14, "days").format("yyyy-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    () => new Date().toISOString().split("T")[0]
  );

  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();

  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const response = await axios.post(`${baseUrl}${allMatchesApi}`, {
        startDate: startDate,
        endDate: endDate,
      });
      console.log(response, "response");
      setMatches(response?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const handleStartDate = (e) => {
    // const date = new Date(e.value).toISOString().split("T")[0];
    setStartDateForCalendar(e.value);
    setStartDate(moment(new Date(e.value)).format("yyyy-MM-DD"));
  };

  const handleEndDate = (e) => {
    // const date = new Date(e.value).toISOString().split("T")[0];
    setEndDateForCalendar(e.value);
    setEndDate(moment(new Date(e.value)).format("yyyy-MM-DD"));
  };

  const handleSearch = () => {
    fetchDataFromBackend({ loading: true });
  };

  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={6}>
      <Drawer active={6} />
      <div className={classes.calendar_container}>
        <Calendar
          className={classes.calendar}
          id="startDate"
          placeholder="Start Date"
          value={startDateForCalendar}
          maxDate={endDateForCalendar}
          onChange={handleStartDate}
          showIcon
        />
        <Calendar
          className={classes.calendar}
          id="endDate"
          placeholder="End Date"
          value={endDateForCalendar}
          minDate={startDateForCalendar}
          onChange={handleEndDate}
          showIcon
        />
        <Button
          label={
            lang == "english"
              ? "Search"
              : lang == "spanish"
              ? "Buscar"
              : lang == "french"
              ? "Recherche"
              : lang == "arabic"
              ? "يبحث"
              : "Search"
          }
          severity="help"
          raised
          className={classes.search_btn}
          onClick={handleSearch}
        />
      </div>
      {loading ? (
        <Loader />
      ) : (
        <>
          <MatchGridLayout>
            <HeadingWithGrid
              title={
                lang == "english"
                  ? "Matches"
                  : lang == "spanish"
                  ? "Partidos"
                  : lang == "french"
                  ? "Allumettes"
                  : lang == "arabic"
                  ? "اعواد الكبريت"
                  : "Matches"
              }
            />
            {matches?.length > 0 ? (
              matches?.map((match, index) => {
                return <MatchLayout match={match} key={index} />;
              })
            ) : (
              <div className={classes.message_box}>
                <p className={classes.message}>
                  {lang == "english"
                    ? "No Matches Found Between"
                    : lang == "spanish"
                    ? "No se encontraron coincidencias entre"
                    : lang == "french"
                    ? "Aucune correspondance trouvée entre"
                    : lang == "arabic"
                    ? "لم يتم العثور على تطابقات بين"
                    : "No Matches Found Between"}{" "}
                  {date(startDate).date}{" "}
                  {lang == "english"
                    ? "and"
                    : lang == "spanish"
                    ? "y"
                    : lang == "french"
                    ? "et"
                    : lang == "arabic"
                    ? "و"
                    : "and"}{" "}
                  {date(endDate).date}
                </p>
              </div>
            )}
          </MatchGridLayout>
        </>
      )}
    </Layout>
  );
};

export default SearchPage;
