import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import classes from "../css/PredictMatchLeaderboard.module.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  baseUrl,
  calculatePredictionPointsApi,
  fetchPlayersApi,
  getUserPredictionApi,
  matchByIdApi,
  matchPredictionLeaderboardApi,
  matchPredictionQuestionsApi,
  saveUserPredictionApi,
  teamLineupsApi,
} from "../api/api";
import axios from "axios";
import { toast } from "react-toastify";
import { resetTeam, setTeam } from "../slices/userPredictionSlice";
import { TabPanel, TabView } from "primereact/tabview";
import Heading from "../components/Heading";
import { Button } from "primereact/button";
import PredictionMenuTabs from "../components/PredictionMenuTabs";
import MatchLayout from "../components/MatchLayout";
import { Dropdown } from "primereact/dropdown";
import PredictionLeaderboard from "../components/Leaderboard/PredictionLeaderboard";
import { Panel } from "primereact/panel";
import { Fieldset } from "primereact/fieldset";
import MatchEvents from "../components/MatchEvents";
import Commentry from "../components/Commentry";

const PredictMatchLeaderboard = () => {
  const [loading, setLoading] = useState(false);

  const { id, homeTeamId, awayTeamId, contestId } = useParams();
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [attackers, setAttackers] = useState([]);
  const [defenders, setDefenders] = useState([]);
  const [midfielders, setMidfielders] = useState([]);
  const [goalkeeper, setGoalkeeper] = useState([]);
  const [homeTeamLineups, setHomeTeamLineups] = useState([]);
  const [awayTeamLineups, setAwayTeamLineups] = useState([]);
  const [allPlayers, setAllPlayers] = useState([]);
  const [questions, setQuestions] = useState([]);

  const [homeTeamEvents2, setHomeTeamEvents2] = useState([]);
  const [awayTeamEvents2, setAwayTeamEvents2] = useState([]);
  const [homeTeamName, setHomeTeamName] = useState("");
  const [awayTeamName, setAwayTeamName] = useState("");

  const [totalPoints, setTotalPoints] = useState("0");

  const [selectedOption1, setSelectedOption1] = useState(null);
  const [selectedOption2, setSelectedOption2] = useState(null);
  const [selectedOption3, setSelectedOption3] = useState(null);
  const [selectedOption4, setSelectedOption4] = useState(null);
  const [selectedOption5, setSelectedOption5] = useState(null);
  const [selectedOption6, setSelectedOption6] = useState(null);
  const [selectedOption7, setSelectedOption7] = useState(null);
  const [selectedOption8, setSelectedOption8] = useState(null);

  const [selectedOption_4, setSelectedOption_4] = useState(null);
  const [selectedOption_7, setSelectedOption_7] = useState(null);
  const [selectedOption_8, setSelectedOption_8] = useState(null);

  const [leaderboard, setLeaderboard] = useState([]);

  const [isUpdating, setIsUpdating] = useState(false);

  const { user } = useSelector((state) => state.userSlice);
  const { prediction } = useSelector((state) => state.userPredictionSlice);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchUserPredictions = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${getUserPredictionApi}`,
        {
          user_id: user?.id,
          match_fixture_id: id,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response?.data?.status == 1) {
        setTotalPoints(response?.data?.result?.total_points);
        const userPredictions = JSON.parse(response?.data?.result?.prediction);
        console.log(userPredictions,'up');
        userPredictions.forEach((prediction, index) => {
          const predictionKey = Object.keys(prediction)[0];
          switch (predictionKey) {
            case "prediction1":
              setSelectedOption1(prediction[predictionKey]);
              break;
            case "prediction2":
              setSelectedOption2(prediction[predictionKey]);
              break;
            case "prediction3":
              setSelectedOption3(prediction[predictionKey]);
              break;
            case "prediction4":
              setSelectedOption4(prediction[predictionKey][0]);
              setSelectedOption_4(prediction[predictionKey][1]);
              break;
            case "prediction5":
              setSelectedOption5(prediction[predictionKey]);
              break;
            case "prediction6":
              setSelectedOption6(prediction[predictionKey]);
              break;
            case "prediction7":
              setSelectedOption7(prediction[predictionKey][0]);
              setSelectedOption_7(prediction[predictionKey][1]);
              break;
            case "prediction8":
              setSelectedOption8(prediction[predictionKey][0]);
              setSelectedOption_8(prediction[predictionKey][1]);
              break;
            default:
              break;
          }
        });
        setIsUpdating(true);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };

  const fetchMatchPredictionQuestions = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${baseUrl}${matchPredictionQuestionsApi}`,
        {
          match_fixture_id: id,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );

      if (response?.data?.status == 1) {
        if (response?.data?.result.length > 0) {
          setQuestions(JSON.parse(response?.data?.result[0]?.questions));
        }
      }
      console.log(response, "response....");
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchDataFromBackend = async (loadDataWithLoading) => {
    try {
      if (loadDataWithLoading?.loading) {
        setLoading(true);
      }
      const res = await axios.get(`${baseUrl}${matchByIdApi}${id}`);
      if (res?.data?.message.length > 0) {
        setData(res?.data?.message[0]);
        const homeTeam = res?.data?.message[0]?.homeTeam;
        setHomeTeamName(homeTeam);
        const awayTeam = res?.data?.message[0]?.awayTeam;
        setAwayTeamName(awayTeam);

        const homeTeamEvents = res?.data?.message[0]?.events?.filter((data) => {
          return (
            data?.team?.name == homeTeam &&
            data?.type == "Goal" &&
            data?.detail != "Missed Penalty"
          );
        });

        setHomeTeamEvents2(homeTeamEvents);
        const awayTeamEvents = res?.data?.message[0]?.events?.filter((data) => {
          return (
            data?.team?.name == awayTeam &&
            data?.type == "Goal" &&
            data?.detail != "Missed Penalty"
          );
        });

        setAwayTeamEvents2(awayTeamEvents);
        CalculateLeaderboardPoints();
      }
      setLoading(false);
    } catch (error) {
      // console.log(error, "error");
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  const CalculateLeaderboardPoints = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${calculatePredictionPointsApi}`,
        {
          operator: user?.operator,
          match_fixture_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response.....");
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  const fetchPredictionLeaderboard = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}${matchPredictionLeaderboardApi}`,
        {
          match_fixture_id: id,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      console.log(response, "response");
      setLeaderboard(response?.data?.result);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  useEffect(() => {
    fetchMatchPredictionQuestions();
    fetchDataFromBackend({ loading: true });
    fetchUserPredictions();
    fetchPredictionLeaderboard();
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 30000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={10} hideMenu={true}>
      <Drawer active={10} />
      <div className={classes.container}>
        <PredictionMenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <>
            <div className={classes.match_container}>
              <MatchLayout match={data} hideButton={true} />
            </div>

            <Heading
              title={
                lang == "english"
                  ? "Your Prediction and Leaderboard"
                  : lang == "spanish"
                  ? "Tu predicción y tabla de clasificación"
                  : lang == "french"
                  ? "Votre prédiction et votre classement"
                  : lang == "arabic"
                  ? "التنبؤ الخاص بك والمتصدرين"
                  : "Your Prediction and Leaderboard"
              }
            />

            <div className={classes.tab_container}>
              <TabView scrollable>
                <TabPanel
                  header={
                    lang == "english"
                      ? "Your Prediction"
                      : lang == "spanish"
                      ? "Tu predicción"
                      : lang == "french"
                      ? "Votre prédiction"
                      : lang == "arabic"
                      ? "التنبؤ الخاص بك"
                      : "Your Prediction"
                  }
                >
                  <>
                    <div className={classes.prediction_container}>
                      <div className={classes.questions_container}>
                        {questions.length > 0 &&
                          questions.map((question) => {
                            return (
                              <div
                                className={classes.question}
                                key={question?.id}
                              >
                                <p className={classes.question_title}>
                                  {question?.name}
                                </p>
                                {question.id == 1 ? (
                                  <>
                                    <Dropdown
                                      value={selectedOption1}
                                      onChange={(e) =>
                                        setSelectedOption1(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption1 ? [selectedOption1] : []
                                      }
                                      optionLabel="answer"
                                      placeholder="Select Team"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption1?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption1?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption1?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 2 ? (
                                  <>
                                    <Dropdown
                                      disabled={true}
                                      value={selectedOption2}
                                      onChange={(e) =>
                                        setSelectedOption2(e.value)
                                      }
                                      options={
                                        selectedOption2 ? [selectedOption2] : []
                                      }
                                      optionLabel="answer"
                                      // virtualScrollerOptions={{ itemSize: 38 }}
                                      placeholder="Select Goals"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption1?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption2?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption2?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 3 ? (
                                  <>
                                    <Dropdown
                                      disabled={true}
                                      value={selectedOption3}
                                      onChange={(e) =>
                                        setSelectedOption3(e.value)
                                      }
                                      options={
                                        selectedOption3 ? [selectedOption3] : []
                                      }
                                      optionLabel="answer"
                                      // virtualScrollerOptions={{ itemSize: 38 }}
                                      placeholder="Select Goals"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption3?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption3?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption3?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 4 ? (
                                  <>
                                    <Dropdown
                                      value={selectedOption4}
                                      onChange={(e) =>
                                        setSelectedOption4(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption4 ? [selectedOption4] : []
                                      }
                                      virtualScrollerOptions={{ itemSize: 38 }}
                                      optionLabel="answer"
                                      placeholder="Select Player"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption4?.answer != "None" && (
                                      <Dropdown
                                        value={selectedOption_4}
                                        onChange={(e) =>
                                          setSelectedOption_4(e.value)
                                        }
                                        disabled={true}
                                        options={
                                          selectedOption_4
                                            ? [selectedOption_4]
                                            : []
                                        }
                                        optionLabel="answer"
                                        // virtualScrollerOptions={{ itemSize: 38 }}
                                        placeholder="Select Goals"
                                        className="w-full md:w-14rem"
                                      />
                                    )}

                                    {selectedOption4?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption4?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption4?.point}+
                                          {selectedOption_4?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 5 ? (
                                  <>
                                    <Dropdown
                                      value={selectedOption5}
                                      onChange={(e) =>
                                        setSelectedOption5(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption5 ? [selectedOption5] : []
                                      }
                                      virtualScrollerOptions={{ itemSize: 38 }}
                                      optionLabel="answer"
                                      placeholder="Select Player"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption5?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption5?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption5?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 6 ? (
                                  <>
                                    <Dropdown
                                      value={selectedOption6}
                                      onChange={(e) =>
                                        setSelectedOption6(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption6 ? [selectedOption6] : []
                                      }
                                      virtualScrollerOptions={{ itemSize: 38 }}
                                      optionLabel="answer"
                                      placeholder="Select Player"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption6?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption6?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption6?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 7 ? (
                                  <>
                                    <Dropdown
                                      value={selectedOption7}
                                      onChange={(e) =>
                                        setSelectedOption7(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption7 ? [selectedOption7] : []
                                      }
                                      virtualScrollerOptions={{ itemSize: 38 }}
                                      optionLabel="answer"
                                      placeholder="Select Player"
                                      className="w-full md:w-14rem"
                                    />
                                    <Dropdown
                                      value={selectedOption_7}
                                      onChange={(e) =>
                                        setSelectedOption_7(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption_7
                                          ? [selectedOption_7]
                                          : []
                                      }
                                      optionLabel="answer"
                                      // virtualScrollerOptions={{ itemSize: 38 }}
                                      placeholder="Select Goals"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption7?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption7?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption7?.point} +{" "}
                                          {selectedOption_7?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : question.id == 8 ? (
                                  <>
                                    <Dropdown
                                      value={selectedOption8}
                                      onChange={(e) =>
                                        setSelectedOption8(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption8 ? [selectedOption8] : []
                                      }
                                      optionLabel="answer"
                                      placeholder="Select Player"
                                      className="w-full md:w-14rem"
                                    />
                                    <Dropdown
                                      value={selectedOption_8}
                                      onChange={(e) =>
                                        setSelectedOption_8(e.value)
                                      }
                                      disabled={true}
                                      options={
                                        selectedOption_8
                                          ? [selectedOption_8]
                                          : []
                                      }
                                      optionLabel="answer"
                                      // virtualScrollerOptions={{ itemSize: 38 }}
                                      placeholder="Select Goals"
                                      className="w-full md:w-14rem"
                                    />
                                    {selectedOption8?.message != "" && (
                                      <Panel header="Prediction Points">
                                        <p className={classes.answer_message}>
                                          {selectedOption8?.message}
                                        </p>
                                        <p
                                          // style={{ margin: "10px 0" }}
                                          className={classes.answer_message}
                                        >
                                          Points : {selectedOption8?.point} +{" "}
                                          {selectedOption_8?.point}
                                        </p>
                                      </Panel>
                                    )}
                                  </>
                                ) : null}
                              </div>
                            );
                          })}

                        <div className={classes.btn_container}>
                          <Fieldset
                            style={{ width: "200px" }}
                            legend={
                              lang == "english"
                                ? "Your Points"
                                : lang == "spanish"
                                ? "Tus puntos"
                                : lang == "french"
                                ? "Vos points "
                                : lang == "arabic"
                                ? "نقاطك "
                                : "Your Points "
                            }
                          >
                            <p className={classes.points}>
                              {lang == "english"
                                ? "Your Points :"
                                : lang == "spanish"
                                ? "Tus puntos:"
                                : lang == "french"
                                ? "Vos points :"
                                : lang == "arabic"
                                ? "نقاطك :"
                                : "Your Points :"}
                              {totalPoints}
                            </p>
                          </Fieldset>
                        </div>

                        {data?.matchStatus != "Match Finished" &&
                          data?.matchTime == null && (
                            <div className={classes.button_container}>
                              <Button
                                className={classes.next_button}
                                label={
                                  lang == "english"
                                    ? "Edit"
                                    : lang == "spanish"
                                    ? "Editar"
                                    : lang == "french"
                                    ? "Modifier"
                                    : lang == "arabic"
                                    ? "يحرر"
                                    : "Edit"
                                }
                                severity="danger"
                                raised
                                onClick={() =>
                                  navigate(
                                    `/match/predict/${id}/${homeTeamId}/${awayTeamId}`
                                  )
                                }
                              />
                            </div>
                          )}
                      </div>
                    </div>
                  </>
                </TabPanel>
                <TabPanel
                  header={
                    lang == "english"
                      ? "Prediction Leaderboard"
                      : lang == "spanish"
                      ? "Tabla de clasificación de predicción"
                      : lang == "french"
                      ? "Classement des prédictions"
                      : lang == "arabic"
                      ? "المتصدرين التنبؤ"
                      : "Prediction Leaderboard"
                  }
                >
                  <PredictionLeaderboard data={leaderboard} match={data} />
                </TabPanel>
                <TabPanel
                  header={
                    lang == "english"
                      ? "Events"
                      : lang == "spanish"
                      ? "Eventos"
                      : lang == "french"
                      ? "Événements"
                      : lang == "arabic"
                      ? "الأحداث"
                      : "Events"
                  }
                >
                  <div className={classes.grid_col_2}>
                    <MatchEvents
                      team_name={homeTeamName}
                      data={homeTeamEvents2}
                    />
                    <MatchEvents
                      team_name={awayTeamName}
                      data={awayTeamEvents2}
                    />
                  </div>
                  <Commentry events={data?.events} />
                </TabPanel>
              </TabView>
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default PredictMatchLeaderboard;
