import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Toolbar, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, checkOtherPlayersTeamApi } from "../../api/api";
import PlayerTeamModal from "../PlayerTeamModal";

function EnhancedTableToolbar(props) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography sx={{ flex: "1 1 100%" }} variant="h6">
        {props.lang == "english"
          ? "LeaderBoard"
          : props.lang == "spanish"
          ? "Tabla de clasificación"
          : props.lang == "french"
          ? "Classement"
          : props.lang == "arabic"
          ? "لوحة المتصدرين"
          : "LeaderBoard"}
      </Typography>
    </Toolbar>
  );
}

const PredictionLeaderboard = ({ data, match }) => {
  const { lang } = useSelector((state) => state.langSlice);
  const { user } = useSelector((state) => state.userSlice);
  const [transformedData, setTransformedData] = React.useState([]);
  const [userRank, setUserRank] = React.useState(null);
  const [show, setShow] = React.useState(false);

  useEffect(() => {
    setTransformedData(() => {
      return data.map((dataItem) => {
        if (dataItem.user_id == user.id) {
          return {
            ...dataItem,
            currentUser: true,
          };
        } else {
          return {
            ...dataItem,
            currentUser: false,
          };
        }
      });
    });

    setUserRank(() => {
      return data.filter((dataItem) => dataItem?.user_id == user.id);
    });

    setShow(() => {
      const find = data
        .slice(0, 10)
        ?.find((dataItem) => dataItem?.user_id == user?.id);
      if (find) {
        return false;
      } else {
        return true;
      }
    });
  }, [data]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        gap: "10px",
        my: 2,
      }}
    >
      <Paper sx={{ width: "300px", mb: 2 }}>
        <EnhancedTableToolbar lang={lang} />
        <TableContainer>
          <Table
            sx={{ maxWidth: 350 }}
            aria-labelledby="tableTitle"
            size="medium"
          >
            <TableHead sx={{ color: "black", border: "1px solid #e5e7eb" }}>
              <TableRow>
                <TableCell sx={{ color: "#343A40" }} align="center">
                  {lang == "english"
                    ? "Rank"
                    : lang == "spanish"
                    ? "Rango"
                    : lang == "french"
                    ? "Rang"
                    : lang == "arabic"
                    ? "رتبة"
                    : "Rank"}
                </TableCell>
                <TableCell sx={{ color: "#343A40" }} align="center">
                  {lang == "english"
                    ? "User Name"
                    : lang == "spanish"
                    ? "Nombre de usuario"
                    : lang == "french"
                    ? "Nom d'utilisateur"
                    : lang == "arabic"
                    ? "اسم المستخدم"
                    : "User Name"}
                </TableCell>
                <TableCell sx={{ color: "#343A40" }} align="center">
                  {lang == "english"
                    ? "Points"
                    : lang == "spanish"
                    ? "Puntos"
                    : lang == "french"
                    ? "Points"
                    : lang == "arabic"
                    ? "نقاط"
                    : "Points"}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transformedData?.length > 0 &&
                transformedData.slice(0, 10).map((dataItem, index) => {
                  return (
                    <TableRow
                      key={index}
                      sx={{
                        cursor: "pointer",
                        backgroundColor: dataItem?.currentUser
                          ? "red"
                          : "transparent",
                      }}
                    >
                      <TableCell
                        sx={{
                          color: dataItem?.currentUser ? "white" : "#343A40",
                          maxWidth: 30,
                        }}
                        align="center"
                      >
                        {dataItem?.user_rank}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: dataItem?.currentUser ? "white" : "#343A40",
                          maxWidth: 30,
                        }}
                        align="center"
                      >
                        {dataItem?.user_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          color: dataItem?.currentUser ? "white" : "#343A40",
                          maxWidth: 30,
                        }}
                        align="center"
                      >
                        {dataItem?.total_points}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      {show && (
        <Paper sx={{ width: "350px", mb: 2 }}>
          <TableContainer>
            <Table
              sx={{ maxWidth: 350 }}
              aria-labelledby="tableTitle"
              size="medium"
            >
              <TableHead sx={{ background: "black", color: "white" }}>
                <TableRow>
                  <TableCell sx={{ color: "white" }} align="center">
                    {lang == "english"
                      ? "Your Rank"
                      : lang == "spanish"
                      ? "Su rango"
                      : lang == "french"
                      ? "Ton rang"
                      : lang == "arabic"
                      ? "رتبتك"
                      : "Your Rank"}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    {lang == "english"
                      ? "Name"
                      : lang == "spanish"
                      ? "Nombre"
                      : lang == "french"
                      ? "Nom"
                      : lang == "arabic"
                      ? "اسم"
                      : "Name"}
                  </TableCell>
                  <TableCell sx={{ color: "white" }} align="center">
                    {lang == "english"
                      ? "Points"
                      : lang == "spanish"
                      ? "Puntos"
                      : lang == "french"
                      ? "Points"
                      : lang == "arabic"
                      ? "نقاط"
                      : "Points"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userRank?.length > 0 &&
                  userRank.map((dataItem, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          sx={{
                            maxWidth: 30,
                          }}
                          align="center"
                        >
                          {dataItem?.user_rank}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 30,
                          }}
                          align="center"
                        >
                          {dataItem?.user_name}
                        </TableCell>
                        <TableCell
                          sx={{
                            maxWidth: 30,
                          }}
                          align="center"
                        >
                          {dataItem?.total_points}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </Box>
  );
};

export default PredictionLeaderboard;
