import React from "react";
import { useRouteError } from "react-router-dom";
import classes from "./ErrorBoundary.module.css";
import Layout from "./Layout";
import Drawer from "./Drawer";

const ErrorBoundary = () => {
  const error = useRouteError();
  console.error(error);
  return (
    <Layout>
      <Drawer />
      <div className={classes.message_container}>
        <p className={classes.text}>Some Error Occured!</p>
        <p className={classes.text}>{error?.message || "Unknown Error"}</p>
      </div>
    </Layout>
  );
};

export default ErrorBoundary;
