import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import MenuTabs from "../components/MenuTabs";
import classes from "../css/MatchContestsPage.module.css";
import { baseUrl, fetchMatchContestsApi, matchByIdApi } from "../api/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import MatchLayout from "../components/MatchLayout";
import { LinearProgress } from "@mui/material";
import Loader from "../components/Loader";
import { Button } from "primereact/button";
import OwlCarousel from "react-owl-carousel";
import CreateContestModal from "../components/CreateContestModal";
import MatchGridCenterLayout from "../components/Match-Container/MatchGridCenterLayout";
import HeadingPrimary from "../components/Headings/HeadingPrimary";

const MatchContestsPage = () => {
  const [data, setData] = useState("");
  const [matchContests, setMatchContests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { id, homeTeamId, awayTeamId } = useParams();
  const { user } = useSelector((state) => state?.userSlice);
  const { lang } = useSelector((state) => state.langSlice);
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const options = {
    loop: true,
    items: 1,
    margin: 0,
    center: true,
    nav: true,
    navText: [
      "<div class='nav-button owl-prev'><i class='pi pi-arrow-left'></i></div>",
      "<div class='nav-button owl-next'><i class='pi pi-arrow-right'></i></div>",
    ],
    autoplay: true,
    dots: false,
    autoplayTimeout: 3000,
    autoplayHoverPause: true,
    smartSpeed: 450,
    responsive: {
      0: {
        items: 1,
      },
      300: {
        items: 1,
      },
      500: {
        items: 1,
      },
      700: {
        items: 1,
      },
      800: {
        items: 1,
      },
    },
  };

  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader.loading == true) {
        setLoading(true);
      }
      const res = await axios.get(`${baseUrl}${matchByIdApi}${id}`);
      setData(res?.data?.message[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data || error?.data?.message || "Something Went Wrong!"
      );
    }
  };

  const fetchMatchContests = async (loader) => {
    try {
      if (loader.loading == true) {
        setLoading(true);
      }
      const response = await axios.post(
        `${baseUrl}${fetchMatchContestsApi}`,
        {
          match_fixture_id: id,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setMatchContests(response?.data?.result);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };
  useEffect(() => {
    fetchDataFromBackend({ loading: true });
    fetchMatchContests({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchMatchContests({ loading: false });
        fetchDataFromBackend({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Layout active={4} hideMenu={true}>
      <Drawer active={4} />
      <div className={classes.container}>
        <MenuTabs />

        {loading ? (
          <Loader />
        ) : (
          <>
            <MatchGridCenterLayout>
              <MatchLayout match={data} hideButton={true} />
            </MatchGridCenterLayout>
            <HeadingPrimary
              title={
                lang == "english"
                  ? "Join Match Contests"
                  : lang == "spanish"
                  ? "Únete a concursos de partidos"
                  : lang == "french"
                  ? "Rejoignez les concours de match"
                  : lang == "arabic"
                  ? "انضم إلى مسابقات المباراة"
                  : "Join Match Contests"
              }
            />

            <div className={classes.match_contests_container}>
              {matchContests?.length > 0 &&
                matchContests?.map((data) => {
                  return (
                    <div className={classes.match_contest_sub_container}>
                      <div className={classes.match_contest_creator}>
                        <p className={classes.contest_creator}>
                          {lang == "english"
                            ? "Contest Created By:"
                            : lang == "spanish"
                            ? "Concurso creado por:"
                            : lang == "french"
                            ? "Concours créé par :"
                            : lang == "arabic"
                            ? "المسابقة تم إنشاؤها بواسطة:"
                            : "Contest Created By:"}{" "}
                          {data?.user_name
                            ? data?.user_name
                            : lang == "english"
                            ? "Admin"
                            : lang == "spanish"
                            ? "Administrador"
                            : lang == "french"
                            ? "Administrateur"
                            : lang == "arabic"
                            ? "مسؤل"
                            : "Admin"}
                        </p>
                      </div>
                      <div className={classes.match_contest_header}>
                        <div className={classes.prize_container}>
                          <p className={classes.prize_heading}>
                            {lang == "english"
                              ? "Prize Pool"
                              : lang == "spanish"
                              ? "Bolsa de premios"
                              : lang == "french"
                              ? "cagnotte"
                              : lang == "arabic"
                              ? "مجموع جوائز"
                              : "Prize Pool"}
                          </p>
                          <p className={classes.prize}>
                            Rs. {data?.prize_pool}
                          </p>
                        </div>
                        <div className={classes.match_fees}>
                          <button type="button" className={classes.fees_btn}>
                            Rs. {data?.entry_fee}
                          </button>
                        </div>
                      </div>

                      <div className={classes.seats_left}>
                        <LinearProgress
                          sx={{ width: "100%" }}
                          variant="determinate"
                          value={
                            data?.users_joined > 0
                              ? (data?.users_joined / data?.limit_users) * 100
                              : 0
                          }
                        />
                      </div>
                      <div className={classes.seats_left_text}>
                        <p className={classes.text}>
                          {lang == "english"
                            ? "Spots Left"
                            : lang == "spanish"
                            ? "Lugares restantes"
                            : lang == "french"
                            ? "Places restantes"
                            : lang == "arabic"
                            ? "البقع اليسرى"
                            : "Spots Left"}{" "}
                          {data?.users_joined > 0
                            ? data?.limit_users - data?.users_joined
                            : data?.limit_users}
                        </p>
                        <p className={classes.text}>
                          {lang == "english"
                            ? "Spots"
                            : lang == "spanish"
                            ? "Lugares"
                            : lang == "french"
                            ? "Taches"
                            : lang == "arabic"
                            ? "بقع"
                            : "Spots"}{" "}
                          {data?.limit_users}
                        </p>
                      </div>

                      {data?.limit_users - data?.users_joined != 0 ? (
                        <div className={classes.btn_container}>
                          <Button
                            label={
                              lang == "english"
                                ? "Join Contest"
                                : lang == "spanish"
                                ? "Unirse al concurso"
                                : lang == "french"
                                ? "Rejoignez le concours"
                                : lang == "arabic"
                                ? "انضم إلى المسابقة"
                                : "Join Contest"
                            }
                            severity="success"
                            raised
                            className={classes.join_btn}
                            onClick={() =>
                              navigate(
                                `/match/create-team/${id}/${homeTeamId}/${awayTeamId}/${data?.id}`
                              )
                            }
                          />
                        </div>
                      ) : (
                        <div className={classes.btn_container}>
                          <Button
                            label={
                              lang == "english"
                                ? "Contest Full"
                                : lang == "spanish"
                                ? "Concurso completo"
                                : lang == "french"
                                ? "Concours complet"
                                : lang == "arabic"
                                ? "المسابقة كاملة"
                                : "Contest Full"
                            }
                            severity="danger"
                            text
                            className={classes.join_btn}
                          />
                        </div>
                      )}

                      <div className={classes.prizes_container}>
                        <p className={classes.text}>
                          {lang == "english"
                            ? "Prize Distribution: "
                            : lang == "spanish"
                            ? "Distribución de premios:"
                            : lang == "french"
                            ? "Distribution des prix :"
                            : lang == "arabic"
                            ? "توزيع الجائزة:"
                            : "Prize Distribution: "}
                        </p>
                        <div className={classes.prizes_sub_container}>
                          <OwlCarousel className="owl-theme" {...options}>
                            <div className={classes.carousel_item}>
                              <p>
                                {lang == "english"
                                  ? "1st Prize:"
                                  : lang == "spanish"
                                  ? "1er Premio:"
                                  : lang == "french"
                                  ? "1er prix :"
                                  : lang == "arabic"
                                  ? "الجائزة الأولى:"
                                  : "1st Prize:"}{" "}
                                {data?.prize1
                                  ? `Rs. ${data?.prize1}`
                                  : lang == "english"
                                  ? "Soon to be Declared"
                                  : lang == "spanish"
                                  ? "Pronto será declarado"
                                  : lang == "french"
                                  ? "Bientôt déclaré"
                                  : lang == "arabic"
                                  ? "قريبا سيتم الإعلان عنها"
                                  : "Soon to be Declared"}
                              </p>
                            </div>
                            <div className={classes.carousel_item}>
                              <p>
                                2nd Prize:{" "}
                                {data?.prize2
                                  ? `Rs. ${data?.prize2}`
                                  : lang == "english"
                                  ? "Soon to be Declared"
                                  : lang == "spanish"
                                  ? "Pronto será declarado"
                                  : lang == "french"
                                  ? "Bientôt déclaré"
                                  : lang == "arabic"
                                  ? "قريبا سيتم الإعلان عنها"
                                  : "Soon to be Declared"}
                              </p>
                            </div>
                            <div className={classes.carousel_item}>
                              <p>
                                3rd Prize:{" "}
                                {data?.prize3
                                  ? `Rs. ${data?.prize3}`
                                  : lang == "english"
                                  ? "Soon to be Declared"
                                  : lang == "spanish"
                                  ? "Pronto será declarado"
                                  : lang == "french"
                                  ? "Bientôt déclaré"
                                  : lang == "arabic"
                                  ? "قريبا سيتم الإعلان عنها"
                                  : "Soon to be Declared"}
                              </p>
                            </div>
                          </OwlCarousel>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>

            <div className={classes.btn_container}>
              <Button
                label={
                  lang == "english"
                    ? "Create Your Own Contest"
                    : lang == "spanish"
                    ? "Crea tu propio concurso"
                    : lang == "french"
                    ? "Créez votre propre concours"
                    : lang == "arabic"
                    ? "قم بإنشاء المسابقة الخاصة بك"
                    : "Create Your Own Contest"
                }
                onClick={() => setOpen(true)}
                severity="danger"
                raised
              />
            </div>
          </>
        )}
      </div>
      <CreateContestModal
        open={open}
        handleClose={handleClose}
        match_fixture_id={id}
        user={user}
        fetchMatchContests={fetchMatchContests}
        homeTeamId={homeTeamId}
        awayTeamId={awayTeamId}
      />
    </Layout>
  );
};

export default MatchContestsPage;
