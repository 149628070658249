import React from "react";
import classes from "./HeadingWithGrid.module.css";

const HeadingWithGrid = ({ title }) => {
  return (
    <div className={classes.heading_container}>
      <h1 className={classes.heading}>{title}</h1>
    </div>
  );
};

export default HeadingWithGrid;
