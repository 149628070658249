import React from "react";
import { Panel } from "primereact/panel";
import classes from "./MatchEvents.module.css";
import { useSelector } from "react-redux";

const MatchEvents = ({ team_name, data }) => {
  const { lang } = useSelector((state) => state.langSlice);
  return (
    <Panel header={team_name}>
      {data?.length > 0 ? (
        data?.map((dataItem) => {
          return (
            <div className={classes.container}>
              <p className={classes.name}>{dataItem?.player?.name}</p>
              <p className={classes.time}>{dataItem?.time?.elapsed}`</p>
            </div>
          );
        })
      ) : (
        <div className={classes.container}>
          <p className={classes.name}>
            {lang == "english"
              ? "No Goals Yet..."
              : lang == "spanish"
              ? "Aún no hay goles..."
              : lang == "french"
              ? "Aucun objectif pour l'instant..."
              : lang == "arabic"
              ? "لا يوجد أهداف حتى الآن..."
              : "No Goals Yet..."}
          </p>
        </div>
      )}
    </Panel>
  );
};

export default MatchEvents;
