import React from 'react';
import classes from "./Circle.module.css";

const Circle = ({ number,colorChange,count }) => {
  return (
    <div className={`${classes.circle} ${colorChange ? 'bg-red-500 text-white' : 'bg-blue-500 text-white'} `}>
      <p>
        {number}
      </p>
      {count>0 && 
        <div className={`${classes.inside}`}>
          {count}
        </div>
      }
    </div>
  );
};

export default Circle;
