import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import axios from "axios";
import { baseUrl, userPredictionRewardsApi } from "../api/api";
import { toast } from "react-toastify";
import Drawer from "../components/Drawer";
import classes from "../css/PointsPage.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import date from "../helpers/date";
import PredictionMenuTabs from "../components/PredictionMenuTabs";
import MatchGridLayout from "../components/Match-Container/MatchGridLayout";
import MessageBoxSimple from "../components/Message-Box/MessageBoxSimple";
import { Paginator } from "primereact/paginator";

const UserPredictionPointsPage = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useSelector((state) => state.userSlice);
  const [matches, setMatches] = useState([]);
  const [totalPoints, setTotalPoints] = useState("");
  const location = useLocation();
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(6);
  const [totalRecords, setTotalRecords] = useState(null);

  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();
  const fetchDataFromBackend = async (loader) => {
    try {
      if (loader?.loading) {
        setLoading(true);
      }
      const response = await axios.post(
        `${baseUrl}${userPredictionRewardsApi}`,
        {
          user_id: user?.id,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      setMatches(response?.data);
      setTotalRecords(response?.data?.length);
      setLoading(false);
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
      setLoading(false);
    }
  };
  useEffect(() => {
    if (!user?.token) {
      navigate("/login", {
        state: {
          status: "redirect",
          path: "/user/prediction/points",
          message: "Login Or Sign Up",
        },
      });
      return;
    }
    fetchDataFromBackend({ loading: true });
    const intervalId = setInterval(() => {
      if (!document.hidden) {
        fetchDataFromBackend({ loading: false });
      }
    }, 15000);
    return () => clearInterval(intervalId);
  }, []);

  const onPageChange = (event) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 200);
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    navigate(`?page=${event?.page + 1}`);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get("page");
    if (!page) {
      setFirst(0);
      setRows(6);
    } else {
      setFirst(6 * (page - 1));
    }
  }, [location]);

  return (
    <Layout active={10} hideMenu={true}>
      <Drawer active={10} />
      <div className={classes.container}>
        <PredictionMenuTabs />
        {loading ? (
          <Loader />
        ) : (
          <>
            <MatchGridLayout>
              {matches?.length > 0 ? (
                <>
                  {matches?.map((match, index) => {
                    return (
                      <div className={classes.match} key={index}>
                        <p className={classes.heading}>
                          {lang == "english"
                            ? match?.matchStatus == "Match Finished"
                              ? "Match Finished"
                              : match?.matchStatus == "Match Not Started"
                              ? "Match About to Start"
                              : "Match Is Live"
                            : lang == "spanish"
                            ? match?.matchStatus == "Match Finished"
                              ? "Partido terminado"
                              : match?.matchStatus == "Match Not Started"
                              ? "Partido a punto de comenzar"
                              : "El partido está en vivo"
                            : lang == "french"
                            ? match?.matchStatus == "Match Finished"
                              ? "Match terminé"
                              : match?.matchStatus == "Match Not Started"
                              ? "Match sur le point de commencer"
                              : "Le match est en direct"
                            : lang == "arabic"
                            ? match?.matchStatus == "Match Finished"
                              ? "انتهت المباراة"
                              : match?.matchStatus == "Match Not Started"
                              ? "المباراة على وشك البدء"
                              : "المباراة حية"
                            : match?.matchStatus == "Match Finished"
                            ? "Match Finished"
                            : match?.matchStatus == "Match Not Started"
                            ? "Match About to Start"
                            : "Match Is Live"}
                        </p>
                        <div className={classes.match_info}>
                          <div className={classes.home_team}>
                            <img
                              src={match?.home_team_logo}
                              alt={match?.home_team_name}
                              className={classes.logo}
                            />
                            <p className={classes.text}>
                              {match?.home_team_name}
                            </p>
                          </div>
                          <div className={classes.away_team}>
                            <img
                              src={match?.away_team_logo}
                              alt={match?.away_team_name}
                              className={classes.logo}
                            />
                            <p className={classes.text}>
                              {match?.away_team_name}
                            </p>
                          </div>
                          <div className={classes.match_time}>
                            <p className={classes.text}>
                              {date(match?.match_date_time)?.date},{" "}
                              {date(match?.match_date_time)?.time}
                            </p>
                          </div>
                          <div className={classes.divider}></div>
                          <div className={classes.grid}>
                            <div className={classes.info}>
                              <p className={classes.text}>
                                {lang == "english"
                                  ? "Rank:"
                                  : lang == "spanish"
                                  ? "Rango:"
                                  : lang == "french"
                                  ? "Rang:"
                                  : lang == "arabic"
                                  ? "رتبة:"
                                  : "Rank:"}{" "}
                                {match?.user_rank}
                              </p>
                            </div>
                            <div className={classes.info}>
                              <p className={classes.text}>
                                {lang == "english"
                                  ? "Points:"
                                  : lang == "spanish"
                                  ? "Puntos:"
                                  : lang == "french"
                                  ? "Points:"
                                  : lang == "arabic"
                                  ? "نقاط:"
                                  : "Points:"}{" "}
                                {match?.total_points}
                              </p>
                            </div>
                          </div>

                          <div className={classes.btn_container}>
                            <button
                              type="button"
                              className={classes.btn}
                              onClick={() =>
                                navigate(
                                  `/user/match/prediction/${+match?.match_fixture_id}/${+match?.home_team_id}/${+match?.away_team_id}`
                                )
                              }
                            >
                              {lang == "english"
                                ? "Check Your Prediction"
                                : lang == "spanish"
                                ? "Comprueba tu predicción"
                                : lang == "french"
                                ? "Vérifiez votre prédiction"
                                : lang == "arabic"
                                ? "تحقق من توقعاتك"
                                : "Check Your Prediction"}
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                      <div className={classes.pagination}>
                  <Paginator
                    first={first}
                    rows={rows}
                    totalRecords={totalRecords}
                    onPageChange={onPageChange}
                  />
                </div>
                </>
              ) : (
                <MessageBoxSimple
                  title={
                    lang == "english"
                      ? "No Match Points ...."
                      : lang == "spanish"
                      ? "No hay puntos de partido...."
                      : lang == "french"
                      ? "Pas de points de match...."
                      : lang == "arabic"
                      ? "لا توجد نقاط المباراة ...."
                      : "No Match Points ...."
                  }
                />
              )}
            </MatchGridLayout>
          </>
        )}
      </div>
    </Layout>
  );
};

export default UserPredictionPointsPage;
