import React from "react";
import classes from "./Heading.module.css";

const Heading = ({ title }) => {
  return (
    <div className={classes.heading_container}>
      <h1 className={classes.heading}>{title}</h1>
    </div>
  );
};

export default Heading;
