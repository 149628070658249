import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  prediction: [],
};

const userPredictionSlice = createSlice({
  name: "userPrediction",
  initialState,
  reducers: {
    addPlayerPrediction: (state, action) => {
      if (state.prediction.find((data) => data?.id == action.payload?.id)) {
        return;
      } else {
        state.prediction = [...state.prediction, action.payload];
        return state;
      }
    },
    removePlayerPrediction: (state, action) => {
      state.prediction = state.prediction.filter(
        (data) => data?.id != action.payload
      );
      return state;
    },
    setTeam: (state, action) => {
      state.prediction = action.payload;
      return state;
    },
    resetTeam: (state, action) => {
      state.prediction = [];
      return state;
    },
  },
});

export const {
  addPlayerPrediction,
  removePlayerPrediction,
  setTeam,
  resetTeam,
} = userPredictionSlice.actions;
export default userPredictionSlice;
