import React from "react";
import classes from "./MatchComparisonProgressBar.module.css"; // Import custom CSS file for styling

const MatchComparisonProgressBar = ({
  percentage1,
  percentage2,
  value1,
  value2,
  name,
}) => {
  return (
    <div className={classes.container}>
      <div className={classes.text}>{name}</div>
      <div className={classes.combined_progress_bar_container}>
        <div className={classes.right}>{value1}</div>

        <div className={classes.combined_progress_bar}>
          <div className={classes.progress_segment_container_end}>
            <div
              style={{
                width: `${percentage1}%`,
                backgroundColor: "rgb(153 27 27)",
              }}
            ></div>
          </div>

          <div className={classes.progress_segment_container_start}>
            <div
              style={{
                width: `${percentage2}%`,
                backgroundColor: "rgb(22 101 52)",
              }}
            ></div>
          </div>
        </div>
        <div className={classes.left}>{value2}</div>
      </div>
    </div>
  );
};

export default MatchComparisonProgressBar;
