import React from "react";
import classes from "./MatchLayout.module.css";
import date from "../helpers/date";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const MatchLayout = ({ match, hideButton }) => {
  const { lang } = useSelector((state) => state.langSlice);
  const navigate = useNavigate();
  const handleNavigate = (path) => {
    navigate(`/match/${path?.id}/${path?.homeTeamId}/${path?.awayTeamId}`);
  };

  const handleNavigateToContests = (path) => {
    if (user?.token) {
      navigate(
        `/match/contests/${path?.id}/${path?.homeTeamId}/${path?.awayTeamId}`
      );
    } else {
      navigate("/login", {
        state: {
          status: "redirect",
          path: `/match/contests/${path?.id}/${path?.homeTeamId}/${path?.awayTeamId}`,
          message: "Login or Signup to join match contests....",
        },
      });
    }
  };

  const { user } = useSelector((state) => state?.userSlice);

  return (
    <div
      className={`${classes.match_flex_col} ${
        hideButton && classes.height_adjustment
      }`}
    >
      {match?.matchStatus == "Match Finished" && (
        <div className={classes.match_status}>
          <p className={classes.status_text}>FT</p>
        </div>
      )}
      {match?.matchTime != null && match?.matchStatus != "Match Finished" && (
        <>
          <div className={classes.match_status}>
            <p className={classes.status_text}>
              <i
                className={`fa fa-circle ${classes.text_danger_glow} ${classes.blink}`}
                style={{ fontSize: "10px" }}
              ></i>
              <span style={{ marginLeft: "10px" }}>
                {match?.matchStatus === "Halftime" ? (
                  lang == "english" ? (
                    "Half-Time"
                  ) : lang == "spanish" ? (
                    "Medio tiempo"
                  ) : lang == "french" ? (
                    "Mi-temps"
                  ) : lang == "arabic" ? (
                    "نصف الوقت"
                  ) : (
                    "Half-Time"
                  )
                ) : (
                  <>{match?.matchTime}`</>
                )}
              </span>
            </p>
          </div>
        </>
      )}
      <div className={classes.match_container}>
        <div className={classes.match_sub_container}>
          <div className={classes.home_team_section}>
            <div className={classes.home_team_logo_name}>
              <div className={classes.home_logo_container}>
                <img
                  src={match?.homeLogo}
                  alt={match?.homeTeam}
                  className={classes.home_logo}
                />
              </div>
              <div className={classes.home_team_name}>
                <p className={classes.name}>{match?.homeTeam}</p>
              </div>
            </div>
            <div className={classes.home_team_goals}>
              <p className={classes.name}>{match?.homeGoals}</p>
            </div>
          </div>

          <div className={classes.away_team_section}>
            <div className={classes.away_team_logo_name}>
              <div className={classes.away_logo_container}>
                <img
                  src={match?.awayLogo}
                  alt={match?.awayTeam}
                  className={classes.away_logo}
                />
              </div>
              <div className={classes.away_team_name}>
                <p className={classes.name}>{match?.awayTeam}</p>
              </div>
            </div>
            <div className={classes.away_team_goals}>
              <p className={classes.name}>{match?.awayGoals}</p>
            </div>
          </div>
        </div>

        <div className={classes.match_date_container}>
          <div className={classes.line}></div>
          <div className={classes.match_date_time}>
            <p className={classes.date}>{date(match?.time)?.date}</p>
            <p className={classes.time}>{date(match?.time)?.time}</p>
          </div>
        </div>
      </div>
      {!hideButton && (
        <div className={classes.match_btns}>
          <button
            className={classes.monitor_btn}
            type="button"
            onClick={() =>
              handleNavigate({
                id: match?.fixture_id,
                homeTeamId: match?.homeTeamId,
                awayTeamId: match?.awayTeamId,
              })
            }
          >
            {lang == "english"
              ? "View Match"
              : lang == "spanish"
              ? "Ver partido"
              : lang == "french"
              ? "Voir la correspondance"
              : lang == "arabic"
              ? "عرض المباراة"
              : "View Match"}
          </button>
          {match?.matchStatus != "Match Finished" &&
            match?.matchTime == null && (
              <button
                className={classes.monitor_btn}
                type="button"
                onClick={() =>
                  handleNavigateToContests({
                    id: match?.fixture_id,
                    homeTeamId: match?.homeTeamId,
                    awayTeamId: match?.awayTeamId,
                  })
                }
              >
                {lang == "english"
                  ? "Create Fantasy Team"
                  : lang == "spanish"
                  ? "Crear equipo de fantasía"
                  : lang == "french"
                  ? "Créer une équipe fantastique"
                  : lang == "arabic"
                  ? "إنشاء فريق الخيال"
                  : "Create Fantasy Team"}
              </button>
            )}
        </div>
      )}
    </div>
  );
};

export default MatchLayout;
