import * as React from "react";
import Box from "@mui/material/Box";
import { Typography, TextField, Slider } from "@mui/material";
import Modal from "@mui/material/Modal";
import classes from "./CreateContestModal.module.css";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { baseUrl, createUserContestApi } from "../api/api";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useSelector } from "react-redux";

export default function CreateContestModal({
  open,
  handleClose,
  match_fixture_id,
  user,
  fetchMatchContests,
  awayTeamId,
  homeTeamId,
}) {
  const [limit, setLimit] = React.useState(5);
  const [entryFees, setEntryFees] = React.useState("");
  const { lang } = useSelector((state) => state.langSlice);

  const navigate = useNavigate();

  const handleLimitChange = (event, newValue) => {
    setLimit(newValue);
  };

  const handleCreateContest = async () => {
    try {
      if (entryFees.trim().length == 0) {
        toast.error(
          lang == "english"
            ? "Enter the Entry Fees!"
            : lang == "spanish"
            ? "¡Ingrese las tarifas de inscripción!"
            : lang == "french"
            ? "Entrez les frais d'entrée!"
            : lang == "arabic"
            ? "أدخل رسوم الدخول!"
            : "Enter the Entry Fees!"
        );
        return;
      }
      if (entryFees > 100) {
        toast.error(
          lang == "english"
            ? "Entry Fees cannot be more than 100!"
            : lang == "spanish"
            ? "¡Las tarifas de inscripción no pueden ser más de 100!"
            : lang == "french"
            ? "Les frais d'inscription ne peuvent pas dépasser 100 !"
            : lang == "arabic"
            ? "رسوم الدخول لا يمكن أن تكون أكثر من 100!"
            : "Entry Fees cannot be more than 100!"
        );
        return;
      }

      if (entryFees <= 0) {
        toast.error(
          lang == "english"
            ? "Entry Fees cannot be less than 0 or 0!"
            : lang == "spanish"
            ? "¡Las tarifas de inscripción no pueden ser inferiores a 0 o 0!"
            : lang == "french"
            ? "Les frais d'entrée ne peuvent pas être inférieurs à 0 ou 0 !"
            : lang == "arabic"
            ? "لا يمكن أن تكون رسوم الدخول أقل من 0 أو 0!"
            : "Entry Fees cannot be less than 0 or 0!"
        );
        return;
      }

      const response = await axios.post(
        `${baseUrl}${createUserContestApi}`,
        {
          match_fixture_id: match_fixture_id,
          entry_fees: entryFees,
          limit: limit,
          user_id: user?.id,
          user_name: user?.user_name,
          operator: user?.operator,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.token}`,
          },
        }
      );
      if (response?.data?.status == 0) {
        toast.error(response?.data?.message);
        return;
      }
      console.log(response, "response..... for navigation..........");
      setLimit(5);
      setEntryFees("");
      handleClose();
      fetchMatchContests({ loading: true });
    } catch (error) {
      toast.error(
        error?.response?.data?.message ||
          error?.data?.message ||
          error?.message ||
          error
      );
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="create-contest"
        aria-describedby="create-your-contest-here"
      >
        <Box className={classes.modal}>
          <div className={classes.modal_title}>
            <h1 className={classes.title}>
              {lang == "english"
                ? "Create your own contest"
                : lang == "spanish"
                ? "Crea tu propio concurso"
                : lang == "french"
                ? "Créez votre propre concours"
                : lang == "arabic"
                ? "قم بإنشاء المسابقة الخاصة بك"
                : "Create your own contest"}
            </h1>
          </div>

          <div className={classes.input_box}>
            <InputText
              keyfilter="int"
              value={entryFees}
              onChange={(e) => setEntryFees(e.target.value)}
              className="p-inputtext-lg"
              placeholder={
                lang == "english"
                  ? "Entry Fees"
                  : lang == "spanish"
                  ? "Derechos de inscripción"
                  : lang == "french"
                  ? "Frais d'entrée"
                  : lang == "arabic"
                  ? "رسوم الدخول"
                  : "Entry Fees"
              }
              style={{ width: "100%" }}
            />
          </div>
          <div className={classes.input_box}>
            <Slider
              value={limit}
              min={5}
              step={1}
              max={100}
              onChange={handleLimitChange}
              valueLabelDisplay="auto"
            />
            <div className={classes.max_users}>
              <p className={classes.text}>
                {lang == "english"
                  ? "Maximum users that can participate in this contest:"
                  : lang == "spanish"
                  ? "Usuarios máximos que pueden participar en este concurso:"
                  : lang == "french"
                  ? "Nombre maximum d'utilisateurs pouvant participer à ce concours :"
                  : lang == "arabic"
                  ? "الحد الأقصى للمستخدمين الذين يمكنهم المشاركة في هذه المسابقة:"
                  : "Maximum users that can participate in this contest:"}{" "}
                {limit}
              </p>
            </div>
          </div>

          <div className={classes.submit_btn}>
            <Button
              label={
                lang == "english"
                  ? "Create Contest"
                  : lang == "spanish"
                  ? "Crear concurso"
                  : lang == "french"
                  ? "Créer un concours"
                  : lang == "arabic"
                  ? "إنشاء مسابقة"
                  : "Create Contest"
              }
              onClick={handleCreateContest}
              severity="danger"
              raised
            />
          </div>

          <div className={classes.message_box}>
            <p className={classes.message}>
              {lang == "english"
                ? "Prize Pool will be calculated according to the users participated in the contest."
                : lang == "spanish"
                ? "El premio acumulado se calculará en función de los usuarios que participaron en el concurso."
                : lang == "french"
                ? "La cagnotte sera calculée en fonction des utilisateurs ayant participé au concours."
                : lang == "arabic"
                ? "سيتم احتساب مجموع الجوائز وفقًا للمستخدمين المشاركين في المسابقة."
                : "Prize Pool will be calculated according to the users participated in the contest."}
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
