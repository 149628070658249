import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import Drawer from "../components/Drawer";
import Loader from "../components/Loader";
import axios from "axios";
import {
  baseUrl,
  playerTopAssistsApi,
  playerTopScoresApi,
  standingsApi,
} from "../api/api";
import { toast } from "react-toastify";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import classes from "../css/StandingsPage.module.css";
import Heading from "../components/Heading";
import { useSelector } from "react-redux";

const StandingsPage = () => {
  const [standingsData, setStandingsData] = useState([]);
  const [topScores, setTopScores] = useState([]);
  const [topAssists, setTopAssists] = useState([]);
  const [loading, setLoading] = useState(true);

  const { lang } = useSelector((state) => state.langSlice);

  const fetchTopAssistsPlayer = async () => {
    try {
      const res = await axios.get(`${baseUrl}${playerTopAssistsApi}`);
      setTopAssists(res?.data?.response);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data?.message || error?.response?.data?.message || error?.message
      );
    }
  };

  const fetchTopScorePlayers = async () => {
    try {
      const res = await axios.get(`${baseUrl}${playerTopScoresApi}`);
      setTopScores(res?.data?.response);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data?.message || error?.response?.data?.message || error?.message
      );
    }
  };

  const fetchDataFromBackend = async () => {
    try {
      setLoading(true);
      const res = await axios.get(`${baseUrl}${standingsApi}`);
      setStandingsData(res?.data?.response[0]?.league?.standings[0]);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(
        error?.data?.message || error?.response?.data?.message || error?.message
      );
    }
  };
  useEffect(() => {
    fetchDataFromBackend();
    // fetchTopScorePlayers();
    // fetchTopAssistsPlayer();
  }, []);
  return (
    <Layout active={5}>
      <Drawer active={5} />
      {loading ? (
        <Loader />
      ) : (
        <div className={classes.container}>
          <Heading
            title={
              lang == "english"
                ? "Premier League Standings"
                : lang == "spanish"
                ? "Clasificación de la Premier League"
                : lang == "french"
                ? "Classement de la Premier League"
                : lang == "arabic"
                ? "ترتيب الدوري الممتاز"
                : "Premier League Standings"
            }
          />
          <DataTable value={standingsData} className={classes.table}>
            <Column
              field="rank"
              header={
                lang == "english"
                  ? "Club"
                  : lang == "spanish"
                  ? "Club"
                  : lang == "french"
                  ? "Club"
                  : lang == "arabic"
                  ? "النادي"
                  : "Club"
              }
              body={(rowData) => (
                <div className={classes.team_info}>
                  <p className={classes.text}>{rowData?.rank}</p>
                  <img
                    src={rowData?.team?.logo}
                    alt={rowData?.team?.name}
                    className={classes.logo}
                  />
                  <p className={classes.text}>{rowData?.team?.name}</p>
                </div>
              )}
              style={{
                position: "sticky",
                left: 0,
                backgroundColor: "#F9FAFB",
                zIndex: 1,
                maxWidth: "150px",
              }}
              headerStyle={{
                fontSize: "14px",
                color: "#343A40",
              }}
            />
            <Column
              field="all.played"
              header={
                lang == "english"
                  ? "MP"
                  : lang == "spanish"
                  ? "DIPUTADO"
                  : lang == "french"
                  ? "DÉPUTÉ"
                  : lang == "arabic"
                  ? "النائب"
                  : "MP"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.all.played}</p>
              )}
            />
            <Column
              field="all.win"
              header={
                lang == "english"
                  ? "W"
                  : lang == "spanish"
                  ? "W"
                  : lang == "french"
                  ? "W"
                  : lang == "arabic"
                  ? "دبليو"
                  : "W"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.all.win}</p>
              )}
            />
            <Column
              field="all.draw"
              header={
                lang == "english"
                  ? "D"
                  : lang == "spanish"
                  ? "D"
                  : lang == "french"
                  ? "D"
                  : lang == "arabic"
                  ? "د"
                  : "D"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.all.draw}</p>
              )}
            />
            <Column
              field="all.lose"
              header={
                lang == "english"
                  ? "L"
                  : lang == "spanish"
                  ? "L"
                  : lang == "french"
                  ? "L"
                  : lang == "arabic"
                  ? "ل"
                  : "L"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.all.lose}</p>
              )}
            />
            <Column
              field="all.goals.for"
              header={
                lang == "english"
                  ? "GF"
                  : lang == "spanish"
                  ? "novia"
                  : lang == "french"
                  ? "Petite amie"
                  : lang == "arabic"
                  ? "فرنك غيني"
                  : "GF"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.all.goals.for}</p>
              )}
            />
            <Column
              field="all.goals.against"
              header={
                lang == "english"
                  ? "GA"
                  : lang == "spanish"
                  ? "Georgia"
                  : lang == "french"
                  ? "Géorgie"
                  : lang == "arabic"
                  ? "جا"
                  : "GA"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.all.goals.against}</p>
              )}
            />
            <Column
              field="goalsDiff"
              header={
                lang == "english"
                  ? "GD"
                  : lang == "spanish"
                  ? "DIOS"
                  : lang == "french"
                  ? "DG"
                  : lang == "arabic"
                  ? "جي دي"
                  : "GD"
              }
              style={{
                fontSize: "14px",
                color: "#343A40",
              }}
              body={(rowData) => (
                <p className={classes.text}>{rowData.goalsDiff}</p>
              )}
            />
          </DataTable>
        </div>
      )}
    </Layout>
  );
};

export default StandingsPage;
